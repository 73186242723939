import React, { useEffect, useState } from "react";
import { Box, Grid, Rating, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarContainer } from "../../../component/organism";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import { QuizAttemptCard, QuizMainCard } from "../../../component/molecule";
import { QuizFirstCard } from "../../../component/molecule";
import { CircularWithValueLabel } from "../../../component/atom";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { IconButtonComponent } from "../../../component/atom";
import moment from 'moment';
import _ from "lodash";
import { USER_ROLE } from "../../../core/Constant";
import { BarChart } from "@mui/x-charts";

const valueFormatter = (value) => `${value}`;

const chartSetting = {
  yAxis: [
    {
      label: 'Marks (%)',
    },
  ],
  series: [{ dataKey: 'marks', label: 'Marks', valueFormatter }],
  // height: 300,
  // sx: {
  //   [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
  //     transform: 'translateX(-10px)',
  //   },
  // },
};

const QuizAttemptScreen = ({ stdgetquizAttempts, attemptsData, }) => {
  const navigate = useNavigate();
  const [newquiz, setQuiz] = useState('');
  const [attempts, setAttempts] = useState([]);
  const location = useLocation();
  const [userType, setUserType] = useState(1);
  const [newList, setNewList]=useState([])
  

  useEffect(() => {
    getUserType()
  }, []);

  const getUserType = async () => {
    const type = localStorage.getItem('userType');
    setUserType(type)
  };


  useEffect(() => {
    const qId = location.state?.quiz;
    setQuiz(qId)
    stdgetquizAttempts(qId?.id);
  }, [location]);

  useEffect(() => {
    setAttempts(attemptsData)
    setNewList(_.orderBy(attemptsData,['attempts'],['asc']))
  }, [attemptsData]);

  const onViewAttemptForm = (id, user, quizid, name, attempt) => {
    navigate('/quiz-attempt-form', { state: { quiz_status_id: id, user_id: user, quiz_id: quizid, quiz_name: name, attempt } });
  }

  return (< >
    <Box className="main-screen-container">
      <Box>
        <IconButtonComponent onclick={() => navigate(userType == USER_ROLE.admin ? -1 : '/quiz')} btnType="backbtn" btnText="Back" />
      </Box>
      {/* <Box>
        <HeadingComponent text={""} fontweigth={600} size={40} fontfamily={"Montserrat"} />
      </Box> */}
      <Box sx={{ display: 'flex', flexDirection: 'row', width: 1, justifyContent: 'space-between', mt:2.5}}>
        <Box>
          <p className="quiz-attempt-text">{newquiz?.id < 10 ? 'Quiz 0' + newquiz?.id : newquiz?.id}</p>
          <p className="quiz-attempt-subtext">{newquiz?.name}</p>
        </Box>
      </Box>
      <Box>
        <Grid container>
        <Grid item sx={{display:'flex' , justifyContent:'center'}} xs={12} >
          <HeadingComponent text={`Quiz Progress`} fontfamily={"Montserrat"} fontweigth={560} size={25} />
          </Grid>
          <Grid item sx={{display:'flex', justifyContent:'center' }} xs={12}>
          <Box>
                {/* <BarChart
                  xAxis={[
                    {
                      id: 'barCategories',
                      data: [{marks:'20',marks:'79'}],
                      scaleType: 'band',
                      label: 'marks', valueFormatter
                    },
                  ]}
                  className="css-1vuxth3-MuiBarElement-root"
                  series={[
                    {
                      data: [],
                    },
                  ]}
                  width={600}
                  height={240}
                  // margin={{top:0}}
                  tooltip={{ trigger: 'axis' }}
                  viewBox={"0 100 600 400"}
                /> */}
                {newList?.length>0 ? <BarChart
                  xAxis={[
                    { scaleType: 'band', dataKey: 'attempts', tickPlacement:'middle', tickLabelPlacement:'middle', label:'Attempts' },
                  ]}
                  dataset={newList}
                  className="css-1vuxth3-MuiBarElement-root"
                  
                  {...chartSetting}
                  width={600}
                  height={400}
                  // margin={{top:0}}
                  tooltip={{ trigger: 'axis' }}
                  viewBox={"0 100 600 400"}
                /> : null}
              </Box>
          </Grid>
        </Grid>
      </Box>
      {
        attemptsData == [] ? <p>loading</p> : <Box  width={'100%'} padding={0} alignItems={'center'} justifyContent={'center'} p={1}>
          {/* <QuizFirstCard/> */}
          {/* <QuizMainCard/> */}
          {
            attempts.map((item, index) => {
              return (
                <QuizAttemptCard
                  index={attempts?.length - index}
                  key={`attempt${index}`}
                  onButtonClick={() => onViewAttemptForm(item.id, item.user_id, item.quiz_id, newquiz?.name, item?.attempts)}
                  attempt={item.attempts}
                  time={moment(new Date(_.get(item, 'finished_at', new Date()))).format('hh:mm a')}
                  date={moment(new Date(_.get(item, 'finished_at', new Date()))).format('Do MMM YYYY')}
                  marks={item.marks} />
              )
            })}
        </Box>
      }


    </Box>
  </>);
}

export default connect(state => ({
  attemptsData: state.quizes.get('attemptsData'),
  startData: state.quizes.get('startData')
}),
  {
    stdgetquizAttempts: Actions.quizes.stdgetquizAttempts,
  }
)(QuizAttemptScreen); 
