import React, { useEffect, useState } from "react";
import { Box, DialogActions, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { NumberInput, TextInputComponent } from "../../atom";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import { Actions } from "../../../core/modules/Actions";
import TextAreaComponent from "../../atom/Inputs/TextArea";


const NotificationEditForm = ({ onClose, notificationDetails }) => {
    const [notificationName, setNotificationName] = useState();
    const [notificationTitle, setNotificationTitle] = useState();
    const [notificationContent, setNotificationContent] = useState();
    const [notificationstatus,setNotificationStatus] = useState();
    const [sendingduration,setSendingDuration] = useState()
    const dispatch = useDispatch();

    useEffect(() => {
        setNotificationName(notificationDetails?.name);
        setNotificationTitle(notificationDetails?.title)
        setNotificationContent(notificationDetails?.content)
        setNotificationStatus(notificationDetails?.status)
        setSendingDuration(notificationDetails?.sending_duration)
    }, [notificationDetails]);

    const handleSubmit = async (event) => {
         dispatch(Actions.notification.createAdminNotification({id:notificationDetails?.id, name:notificationName,title:notificationTitle,notification_content:notificationContent,status:notificationstatus,sending_duration:sendingduration}))
        onClose();
    };
    return (
        <>
                <DialogContent>
                    <Box>
                        <NumberInput
                            label={"Set Duration"}
                            name="Duration"
                            value={sendingduration}
                            onchange={(e) => setSendingDuration(e.target.value)}
                            placeholder="Set Duration"
                            readOnly={false}
                            type="number"
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Box sx={{ display: "flex", width: "50%", mr: 2.2, mb: 2, ml: 2.2 }}>
                        <Box sx={{ display: "flex", gap: 0.5, flexGrow: 1 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <TextButtonComponet text={"Update"} classStyle="btn btn-primary" onButtonClick={handleSubmit} />
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <TextButtonComponet text={"Cancel"} classStyle="btn btn-secondary" onButtonClick={() => onClose()} />
                            </Box>
                        </Box>

                    </Box>
                </DialogActions>

        </>
    );
}

export default NotificationEditForm;
