import React, { useEffect, useState } from "react";
import { Box, DialogActions, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { TextInputComponent } from "../../atom";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import { Actions } from "../../../core/modules/Actions";
import TextAreaComponent from "../../atom/Inputs/TextArea";


const NotificationViewForm = ({ onClose, notificationDetails }) => {
    const [notificationName, setNotificationName] = useState();
    const [notificationTitle, setNotificationTitle] = useState();
    const [notificationContent, setNotificationContent] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        setNotificationContent(notificationDetails?.content)
    }, [notificationDetails]);

    return (
        <>
                <DialogContent>
                    {/* <Box>
                        <TextInputComponent
                            label={"Name"}
                            name="name"
                            value={notificationName}
                            onchange={(e) => setNotificationName(e.target.value)}
                            placeholder="Name"
                            readOnly={false}
                        />
                    </Box>
                    <Box>
                        <TextInputComponent
                            label={"Title"}
                            name="title"
                            value={notificationTitle}
                            onchange={(e) => setNotificationTitle(e.target.value)}
                            placeholder="Title"
                            readOnly={false}
                        />
                    </Box>
                    <Box>
                        <TextAreaComponent
                            textlabel={"Content"}
                            name="content"
                            value={notificationContent}
                            onchange={(e) => setNotificationContent(e.target.value)}
                            placeholder="Content"
                            readOnly={false}
                        />
                    </Box> */}
                     <Box>
                    <div dangerouslySetInnerHTML={{ __html: notificationContent }} />
                </Box>
                </DialogContent>

                <DialogActions>
                    {/* <Box sx={{ display: "flex", width: "50%", mr: 2.2, mb: 2, ml: 2.2 }}>
                        <Box sx={{ display: "flex", gap: 0.5, flexGrow: 1 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <TextButtonComponet text={"Update"} classStyle="btn btn-primary" onButtonClick={handleSubmit} />
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <TextButtonComponet text={"Cancel"} classStyle="btn btn-secondary" onButtonClick={() => onClose()} />
                            </Box>
                        </Box>

                    </Box> */}
                </DialogActions>

        </>
    );
}

export default NotificationViewForm;
