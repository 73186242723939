import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  SvgIcon,
  Typography,
} from "@mui/material";
import StarRatingoComponent from "../../atom/Buttons/StarRating";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import { IMAGES } from "../../../assets/Images";

const QuizMarkCard = ({
  isAttempt = false,
  status,
  date,
  title,
  duration,
  attempts,
  week,
  onViewQuiz = () => { },
  onViewAttempsts = () => { },
}) => {




  return (
    <Card sx={{ maxWidth: 260, minWidth: 260, height: 400, borderRadius: 4 }}>
      <Grid
        container
        justifyContent={"space-between"}
        sx={{ paddingInlineEnd: 2, pt: 2 }}
      >
        <Grid item>
          <SvgIcon style={{ width: 100, height: 25 }}>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 146 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0H146L125.073 20.6304L146 39H0V0Z" fill="#9834F0" />
            </svg>
          </SvgIcon>
        </Grid>
        <Grid item>
          <span className="quiz-card-subtext">{date}</span>
        </Grid>
      </Grid>
      <CardContent sx={{ height: 230 }}>
        <Grid
          container
          flexDirection={"column"}
          alignItems={"center"}
          rowGap={1}
        >
          <Grid sx={{height: 40}} item>
            <span className="quiz-card-txt" style={{ textAlign: 'center' }} title={title}>
              {title}
            </span>
          </Grid>
          <Grid sx={{height:30}} item>
            <span className="quiz-card-content-txt">{week}</span>
          </Grid>

          <Grid item>
            <img src={IMAGES.quizIcon} alt="i" className="quiz-icon" />
          </Grid>
          <Grid item>
            <span className="quiz-card-subtext">{duration} minutes</span>
          </Grid>
          <Grid item>
            <span className="quiz-card-subtext">{attempts} Attempts Left</span>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container flexDirection={"column"} gap={1}>
          {!status ? (
            <>
              <Grid item >
                <TextButtonComponet
                  onButtonClick={onViewAttempsts}
                  text={"View Marks"}
                />
              </Grid>

              <Grid item>
                {attempts > -1 ? (
                  <TextButtonComponet
                    onButtonClick={onViewQuiz}
                    classStyle="btn btn-secondary"
                    text={"Re Attempt"}
                  />
                ) : null}
              </Grid>
            </>
          ) : (
            <Grid item sx={{ display: 'flex', alignItems: 'center', height: 100 }}>
              <TextButtonComponet
                onButtonClick={onViewQuiz}
                text={"Take a Test"}
              />
            </Grid>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
};

export default QuizMarkCard;

const QuizSvg = () => {
  return (
    <SvgIcon style={{ width: 200, height: 200 }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 135 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_103_3989)">
          <path
            d="M48.906 60.1197L49.1392 54.9605C51.164 54.506 53.0987 53.7994 54.9037 52.8711L58.3888 56.6848C59.3 57.6827 60.8471 57.7528 61.8448 56.841L67.6848 51.5064C68.6833 50.5944 68.7524 49.0475 67.8411 48.0495L64.3572 44.2354C65.4438 42.5211 66.3226 40.6585 66.9571 38.6831L72.1167 38.916C73.4668 38.9774 74.6108 37.9326 74.6712 36.5828L75.0279 28.6802C75.0906 27.3295 74.0448 26.1864 72.6945 26.1257L67.5344 25.8923C67.081 23.8669 66.3742 21.933 65.4465 20.1278L69.2602 16.6439C70.2578 15.7321 70.3275 14.1834 69.4163 13.1871L64.0815 7.34742C63.1705 6.34878 61.6222 6.27852 60.624 7.19153L56.8114 10.6745C55.0971 9.58688 53.2342 8.70908 51.2584 8.07404L51.4918 2.91365C51.5528 1.56432 50.5065 0.420297 49.1575 0.359779L41.2565 0.00257513C39.9055 -0.0588473 38.762 0.985989 38.6999 2.33713L38.4674 7.49539C36.4419 7.95016 34.5078 8.65621 32.7032 9.58475L29.2189 5.77135C28.3081 4.77317 26.7588 4.70336 25.7622 5.61577L19.9228 10.9501C18.9237 11.8612 18.8545 13.4086 19.7666 14.4078L23.2493 18.2202C22.1621 19.9345 21.2836 21.7971 20.6482 23.7737L15.4896 23.5408C14.1394 23.4791 12.996 24.5241 12.9346 25.8749L12.578 33.7759C12.5172 35.1255 13.5611 36.2695 14.9115 36.3301L20.0707 36.5642C20.5249 38.5892 21.2313 40.5233 22.16 42.3292L18.3467 45.8127C17.3489 46.7241 17.2782 48.2712 18.1908 49.2691L23.5251 55.1094C24.436 56.1066 25.9843 56.1767 26.9819 55.2656L30.7953 51.7823C32.5096 52.8693 34.3733 53.7468 36.3485 54.3821L36.1156 59.5413C36.0545 60.8917 37.0991 62.0347 38.45 62.0964L46.3506 62.4529C47.7015 62.5144 48.8446 61.4696 48.906 60.1197ZM30.9241 30.6457C31.2473 23.5335 37.2731 18.0282 44.3849 18.3502C51.4976 18.6718 57.0028 24.6974 56.6812 31.8098C56.3598 38.9227 50.3341 44.4271 43.2207 44.1066C36.1086 43.7847 30.6042 37.7579 30.9241 30.6457Z"
            fill="#008C88"
          />
          <path
            d="M28.4186 90.6668L32.6726 89.0704C33.3992 88.7974 33.7679 87.9876 33.4948 87.2599L32.4509 84.4784C33.3962 83.7976 34.2632 82.993 35.0233 82.0748L37.7279 83.3032C38.4342 83.6249 39.2681 83.3121 39.5883 82.6048L41.468 78.4676C41.7884 77.7609 41.4759 76.9266 40.768 76.6062L38.0653 75.3788C38.2561 74.2016 38.2911 73.0207 38.1816 71.8599L40.9641 70.8157C41.6905 70.5435 42.0588 69.7318 41.7857 69.0049L40.1885 64.7511C39.916 64.0237 39.1055 63.656 38.3783 63.9291L35.5975 64.9733C34.9159 64.0277 34.1125 63.1607 33.1938 62.4005L34.4218 59.6972C34.7434 58.9896 34.4304 58.1568 33.7234 57.8355L29.5859 55.9568C28.8787 55.6357 28.0461 55.9483 27.7243 56.6552L26.4965 59.3586C25.3202 59.1681 24.1386 59.1327 22.979 59.2423L21.9342 56.4602C21.6611 55.7337 20.851 55.3657 20.1232 55.6388L15.87 57.2349C15.1423 57.5079 14.7744 58.3186 15.0476 59.0454L16.0918 61.8278C15.1462 62.5095 14.2796 63.3132 13.5191 64.2308L10.8152 63.0033C10.109 62.6819 9.27536 62.9938 8.95442 63.7014L7.07492 67.8392C6.75489 68.5453 7.06699 69.3787 7.77335 69.6994L10.4777 70.928C10.287 72.104 10.2522 73.2855 10.3616 74.4457L7.57875 75.4902C6.8532 75.763 6.48454 76.5732 6.75704 77.3001L8.35383 81.5545C8.62721 82.2813 9.43721 82.6496 10.164 82.3759L12.9459 81.3323C13.6284 82.2776 14.4321 83.1443 15.3485 83.9041L14.1212 86.609C13.7989 87.315 14.1127 88.1491 14.8187 88.4698L18.9575 90.3485C19.6639 90.6699 20.4976 90.3579 20.8176 89.6501L22.0461 86.9458C23.2232 87.1375 24.4041 87.1723 25.5646 87.063L26.6085 89.8447C26.881 90.5722 27.6929 90.9402 28.4186 90.6668ZM21.2094 79.8957C17.4849 78.2052 15.8367 73.8147 17.5282 70.0909C19.219 66.3663 23.6098 64.7185 27.3348 66.4093C31.0587 68.1004 32.706 72.4906 31.0152 76.215C29.3231 79.9396 24.9332 81.5868 21.2094 79.8957Z"
            fill="#008C88"
          />
          <path
            d="M47.8954 109.509L51.6399 113.861C50.3479 115.773 49.3217 117.819 48.5686 119.946L42.8428 119.517C41.3455 119.405 40.0405 120.527 39.9276 122.025L39.2707 130.793C39.158 132.291 40.2807 133.596 41.779 133.709L47.5048 134.138C47.9323 136.353 48.6418 138.53 49.6341 140.612L45.2834 144.356C44.1442 145.336 44.0151 147.053 44.9956 148.191L50.7303 154.856C51.7097 155.996 53.4271 156.124 54.5656 155.144L58.9174 151.399C60.8286 152.691 62.8745 153.718 65.0012 154.47L64.5714 160.196C64.4596 161.694 65.5834 163 67.0808 163.111L75.8479 163.769C77.3466 163.882 78.6523 162.758 78.7637 161.26L79.193 155.535C81.4084 155.108 83.585 154.398 85.668 153.405L89.4128 157.758C90.3918 158.896 92.1091 159.024 93.2474 158.044L99.9112 152.311C101.051 151.33 101.18 149.613 100.2 148.474L96.4553 144.123C97.7476 142.211 98.7738 140.165 99.5267 138.038L105.252 138.468C106.75 138.58 108.055 137.456 108.167 135.959L108.825 127.192C108.937 125.692 107.814 124.388 106.315 124.275L100.591 123.846C100.164 121.63 99.454 119.454 98.4611 117.371L102.812 113.627C103.951 112.647 104.08 110.931 103.1 109.791L97.3652 103.127C96.3864 101.989 94.6689 101.86 93.5302 102.84L89.1781 106.584C87.2669 105.292 85.221 104.266 83.0934 103.513L83.5227 97.7869C83.6356 96.2895 82.5125 94.9844 81.0144 94.873L72.2464 94.2148C70.7492 94.1024 69.4435 95.2255 69.3312 96.7238L68.9013 102.448C66.6862 102.876 64.5096 103.586 62.4278 104.578L58.6827 100.227C57.7027 99.0887 55.9861 98.9596 54.8474 99.9406L48.1835 105.674C47.044 106.654 46.9155 108.371 47.8954 109.509ZM84.9097 119.646C90.071 125.645 89.3924 134.692 83.3944 139.853C77.3951 145.015 68.3478 144.337 63.1862 138.338C58.0248 132.34 58.7022 123.293 64.7016 118.129C70.7002 112.968 79.7473 113.647 84.9097 119.646Z"
            fill="#008C88"
          />
          <path
            d="M89.0007 61.4853L84.4573 63.6918C83.6817 64.0684 83.3569 65.0037 83.735 65.7803L85.1771 68.7512C84.2019 69.6027 83.3255 70.5821 82.5794 71.6771L79.4589 70.5961C78.6439 70.3136 77.7535 70.7464 77.4715 71.5623L75.8203 76.3347C75.5375 77.1508 75.9695 78.0412 76.7858 78.3226L79.9038 79.4021C79.8148 80.7249 79.8986 82.0349 80.1384 83.3086L77.1683 84.752C76.3917 85.1285 76.0678 86.0641 76.4453 86.8398L78.6519 91.3835C79.0293 92.1598 79.964 92.4839 80.7406 92.1061L83.7103 90.6628C84.5625 91.6392 85.5415 92.515 86.6363 93.2626L85.5567 96.3813C85.2739 97.1972 85.7065 98.087 86.5226 98.3687L91.2956 100.02C92.1108 100.304 93.0006 99.871 93.2829 99.0559L94.363 95.9361C95.6843 96.0257 96.9955 95.9431 98.2683 95.702L99.7113 98.6728C100.089 99.4491 101.023 99.7724 101.801 99.3949L106.343 97.1893C107.12 96.8115 107.444 95.8765 107.067 95.0996L105.624 92.1293C106.599 91.2771 107.476 90.2981 108.222 89.2036L111.342 90.2828C112.157 90.5654 113.047 90.134 113.328 89.3182L114.981 84.5452C115.263 83.7292 114.831 82.84 114.016 82.5573L110.896 81.4768C110.985 80.1559 110.902 78.845 110.661 77.5719L113.633 76.1285C114.408 75.7517 114.732 74.8164 114.355 74.0401L112.149 69.4973C111.771 68.7204 110.837 68.3972 110.06 68.7744L107.089 70.2174C106.236 69.2413 105.258 68.3658 104.164 67.6197L105.244 64.4992C105.527 63.6839 105.094 62.7941 104.278 62.5121L99.5045 60.86C98.6892 60.5775 97.7993 61.0089 97.5176 61.8253L96.4378 64.9445C95.1159 64.8551 93.8046 64.9381 92.5322 65.1789L91.0885 62.2083C90.7116 61.4317 89.7764 61.1084 89.0007 61.4853ZM98.092 72.6614C102.389 74.1482 104.667 78.8362 103.18 83.1323C101.692 87.4291 97.004 89.7065 92.707 88.2204C88.4105 86.7325 86.1334 82.0444 87.6205 77.7482C89.1078 73.4511 93.7964 71.1744 98.092 72.6614Z"
            fill="#008C88"
          />
          <path
            d="M7.65332 25.8013L23.846 70.2976L57.1113 58.192L43.577 21.0016L33.6121 16.3545L7.65332 25.8013Z"
            fill="#F0F1F1"
          />
          <path
            d="M43.5786 21.0016L33.6136 16.3545L36.272 23.6608L43.5786 21.0016Z"
            fill="#D0D2D3"
          />
          <path
            d="M40.6946 28.0019L17.5214 36.4353L18.2556 38.4529L41.4288 30.0195L40.6946 28.0019Z"
            fill="#929597"
          />
          <path
            d="M42.0514 31.7221L18.8778 40.1555L19.6121 42.1732L42.7857 33.7398L42.0514 31.7221Z"
            fill="#BBBDBF"
          />
          <path
            d="M43.4015 35.4441L20.2281 43.8772L20.962 45.8937L44.1354 37.4606L43.4015 35.4441Z"
            fill="#BBBDBF"
          />
          <path
            d="M29.2124 25.0873L15.4624 30.0913L16.1966 32.1086L29.9465 27.1047L29.2124 25.0873Z"
            fill="#929597"
          />
          <path
            d="M44.7581 39.1635L21.5846 47.5969L22.3188 49.6143L45.4922 41.1809L44.7581 39.1635Z"
            fill="#BBBDBF"
          />
          <path
            d="M46.7965 44.7686L23.6229 53.2017L24.3567 55.2181L47.5303 46.7851L46.7965 44.7686Z"
            fill="#929597"
          />
          <path
            d="M48.1507 48.4884L24.9773 56.9214L25.7114 58.9388L48.8849 50.5058L48.1507 48.4884Z"
            fill="#BBBDBF"
          />
          <path
            d="M49.5034 52.2097L26.3297 60.6426L27.0637 62.6596L50.2374 54.2267L49.5034 52.2097Z"
            fill="#BBBDBF"
          />
          <path
            d="M122.163 89.5742C122.073 89.2938 121.771 89.1394 121.491 89.2301L118.263 90.2712C117.982 90.3627 117.828 90.663 117.919 90.9444L118.729 93.4551C118.82 93.7361 119.121 93.8903 119.402 93.8L122.63 92.7575C122.91 92.6666 123.064 92.3651 122.973 92.0847L122.163 89.5742Z"
            fill="#F45D81"
          />
          <path
            d="M106.653 56.0512L107.246 50.2407L110.937 54.6684L106.653 56.0512Z"
            fill="#F7CF9C"
          />
          <path
            d="M107.038 52.268L107.244 50.2407L108.533 51.7858L107.038 52.268Z"
            fill="#221F1F"
          />
          <path
            d="M110.935 54.6686L106.651 56.0515L117.936 91.0054L122.22 89.6225L110.935 54.6686Z"
            fill="#FFDC24"
          />
          <path
            d="M122.219 89.6227L117.935 91.0056L118.291 92.1101L122.575 90.7272L122.219 89.6227Z"
            fill="#F0F1F1"
          />
          <path
            d="M110.933 54.6695L108.772 55.3677L120.064 90.3193L122.225 89.6211L110.933 54.6695Z"
            fill="#E8BF15"
          />
          <path
            d="M18.849 38.2802L19.239 36.7482L19.7809 36.3093L19.9942 36.9407L19.6043 38.472L18.849 38.2802Z"
            fill="#F0F1F1"
          />
          <path
            d="M17.9265 39.4595L18.7467 37.4456L20.0858 37.7868L19.8508 39.9276L17.9265 39.4595Z"
            fill="#BE1E2C"
          />
          <path
            d="M18.0337 51.4537C18.2042 50.7854 20.2814 39.8195 20.043 39.4861C19.7907 39.1311 18.2042 38.7893 17.9394 38.8946C17.4212 39.1007 14.2592 49.4587 14.0122 50.4281L5.59825 83.4418C5.33093 84.4912 5.96507 85.5593 7.01415 85.8263L7.23587 85.8821C8.28405 86.15 9.35188 85.5162 9.6198 84.4674L18.0337 51.4537Z"
            fill="#221F1F"
          />
          <path
            d="M7.1158 85.8524L7.01415 85.8262C5.96507 85.5592 5.33093 84.4911 5.59825 83.4417L14.0122 50.4279C14.2592 49.4586 17.4212 39.1006 17.9394 38.8945C18.0767 38.84 18.5701 38.9061 19.0484 39.0315L7.1158 85.8524Z"
            fill="#403F41"
          />
          <path
            d="M10.2731 83.5214C10.3154 83.3563 10.1656 83.1744 9.93733 83.1171L5.44685 81.973C5.22039 81.9142 5.27525 82.0716 5.23381 82.237C5.19111 82.4029 5.34155 82.5849 5.56891 82.6431L9.78447 83.7166C10.0118 83.7749 10.2307 83.6873 10.2731 83.5214Z"
            fill="#BE1E2C"
          />
          <path
            d="M5.167 81.2606C5.30201 80.7311 8.54588 68.7641 8.73211 68.5048C8.91957 68.2454 9.2911 68.9537 9.15655 69.4825C9.02166 70.0116 8.64785 70.2741 8.67666 70.282C8.70562 70.2887 5.63362 81.7385 5.63362 81.7385L5.58821 82.6481C5.58821 82.6481 4.82409 82.6051 5.167 81.2606Z"
            fill="#BE1E2C"
          />
          <path
            d="M50.8153 98.5332C55.9386 110.952 50.0238 125.171 37.6054 130.294C25.1869 135.417 10.9675 129.503 5.84439 117.084C0.721769 104.666 6.63561 90.4463 19.0543 85.3232C31.4726 80.2003 45.6929 86.1148 50.8153 98.5332Z"
            fill="#A6A8AB"
          />
          <path
            d="M47.0609 100.082C51.3286 110.428 46.4014 122.273 36.0563 126.541C25.7111 130.808 13.8656 125.882 9.59774 115.536C5.3302 105.191 10.2565 93.3447 20.6017 89.0772C30.9471 84.8096 42.7932 89.7372 47.0609 100.082Z"
            fill="#F0F1F1"
          />
          <path
            d="M36.0572 126.541C25.712 130.808 13.8665 125.882 9.59865 115.536C5.33111 105.191 10.2574 93.3447 20.6027 89.0771L20.603 89.0768L19.0546 85.3235H19.0543C6.63561 90.4466 0.721769 104.666 5.84439 117.085C10.9675 129.504 25.1869 135.417 37.6054 130.294L36.0572 126.541Z"
            fill="#D0D2D3"
          />
          <path
            d="M9.59774 115.536C13.8656 125.882 25.7111 130.808 36.0563 126.541L20.6021 89.0767L20.6017 89.077C10.2565 93.3445 5.3302 105.191 9.59774 115.536Z"
            fill="white"
          />
          <path
            d="M19.8998 87.751L20.1746 87.6379C20.655 87.4402 21.2056 87.6696 21.4033 88.15L22.825 91.6038C23.0227 92.0838 22.7934 92.634 22.3133 92.8316L22.0382 92.9449C21.5576 93.1427 21.0068 92.9132 20.809 92.4326L19.3877 88.9799C19.1899 88.4995 19.4194 87.9488 19.8998 87.751Z"
            fill="#773249"
          />
          <path
            d="M12.1238 113.314C12.6045 113.117 13.1546 113.346 13.353 113.827L13.4661 114.102C13.6645 114.582 13.4359 115.133 12.9556 115.331L9.50381 116.755C9.02317 116.953 8.47302 116.724 8.27461 116.243L8.16154 115.969C7.9631 115.488 8.19141 114.937 8.67205 114.739L12.1238 113.314Z"
            fill="#6C6E70"
          />
          <path
            d="M44.5383 102.302C44.0574 102.501 43.5072 102.272 43.3088 101.791L43.1954 101.516C42.9973 101.035 43.2256 100.484 43.7065 100.286L47.158 98.8622C47.6386 98.6641 48.1897 98.8939 48.3881 99.3742L48.5012 99.6492C48.6996 100.13 48.4704 100.681 47.9898 100.879L44.5383 102.302Z"
            fill="#6C6E70"
          />
          <path
            d="M19.8998 87.751L20.1746 87.6379C20.655 87.4402 21.2056 87.6696 21.4033 88.15L22.825 91.6038C23.0227 92.0838 22.7934 92.634 22.3133 92.8316L22.0382 92.9449C21.5576 93.1427 21.0068 92.9132 20.809 92.4326L19.3877 88.9799C19.1899 88.4995 19.4194 87.9488 19.8998 87.751Z"
            fill="#6C6E70"
          />
          <path
            d="M33.8401 124.016C33.6417 123.535 33.87 122.986 34.3506 122.787L34.6262 122.674C35.1061 122.475 35.6567 122.704 35.8549 123.185L37.2788 126.637C37.4772 127.118 37.2477 127.667 36.7676 127.865L36.4924 127.979C36.0114 128.177 35.4622 127.949 35.2638 127.468L33.8401 124.016Z"
            fill="#6C6E70"
          />
          <path
            d="M31.2805 108.53C31.7608 108.73 31.9878 109.281 31.7883 109.76L31.6738 110.036C31.4736 110.516 30.9234 110.743 30.4428 110.543L14.4987 103.912C14.0179 103.713 13.7908 103.161 13.9906 102.68L14.1049 102.406C14.3038 101.926 14.8551 101.698 15.3352 101.898L31.2805 108.53Z"
            fill="#403F41"
          />
          <path
            d="M28.8591 111.196C28.6596 111.676 28.1081 111.903 27.6285 111.704L27.3539 111.589C26.8732 111.39 26.6461 110.84 26.8455 110.359L31.7453 98.5794C31.9445 98.0997 32.4955 97.872 32.9759 98.0716L33.2508 98.1864C33.7309 98.3853 33.9581 98.9363 33.7586 99.4173L28.8591 111.196Z"
            fill="#403F41"
          />
          <path
            d="M102.325 119.373L94.6716 136.768C94.5108 137.09 94.1205 137.22 93.7981 137.06L88.7966 134.564C88.475 134.403 88.3444 134.011 88.5052 133.69L97.8024 117.116L102.325 119.373Z"
            fill="#DB1F26"
          />
          <path
            d="M98.7373 120.792L96.3956 119.624L97.8031 117.116L102.326 119.373L94.6723 136.768C94.5115 137.09 94.1212 137.22 93.7988 137.06L91.2532 135.789L98.7373 120.792Z"
            fill="#BE1E2C"
          />
          <path
            d="M95.8462 120.606L97.8029 117.116L102.326 119.373L100.714 123.035L95.8462 120.606Z"
            fill="#D0D2D3"
          />
          <path
            d="M98.2383 121.793L100.105 118.27L102.41 119.42L100.717 123.03L98.2383 121.793Z"
            fill="#A6A8AB"
          />
          <path
            d="M93.5971 101.625C90.3399 108.153 92.9911 116.085 99.5186 119.342C106.047 122.599 113.979 119.949 117.236 113.42C120.494 106.893 117.842 98.9613 111.314 95.7035C104.787 92.4461 96.8542 95.0975 93.5971 101.625ZM95.4359 102.541C98.1863 97.0297 104.885 94.7905 110.398 97.5417C115.909 100.292 118.148 106.991 115.398 112.503C112.647 118.016 105.948 120.255 100.436 117.503C94.9238 114.753 92.6847 108.054 95.4359 102.541Z"
            fill="#403F41"
          />
          <path
            opacity="0.3"
            d="M94.9672 102.309C92.0874 108.08 94.4309 115.092 100.202 117.971C105.973 120.851 112.986 118.508 115.865 112.737C118.745 106.966 116.401 99.9535 110.63 97.0741C104.859 94.1942 97.8472 96.5371 94.9672 102.309Z"
            fill="#E6E6E7"
          />
          <path
            opacity="0.5"
            d="M96.2293 111.286C94.6858 107.357 95.7796 102.722 99.2376 99.9287C102.695 97.1367 107.457 97.0457 110.972 99.3822C110.972 99.3822 105.335 102.017 102.429 104.363C99.4331 106.781 96.2293 111.286 96.2293 111.286Z"
            fill="white"
          />
          <path
            d="M91.2729 5.85969L59.652 44.646L89.1807 68.7195L120.802 29.9332L91.2729 5.85969Z"
            fill="#744C29"
          />
          <path
            d="M91.4478 9.5432L63.4551 43.8784L89.1636 64.838L117.156 30.5028L91.4478 9.5432Z"
            fill="#F0F1F1"
          />
          <path
            d="M112.389 21.5773L109.128 18.9193L110.411 17.346C110.841 16.8181 110.762 16.0412 110.235 15.6107L107.431 13.3244C106.903 12.8939 106.126 12.9738 105.696 13.5017L104.413 15.0748L101.153 12.416C100.625 11.9861 99.8474 12.0652 99.4172 12.5925L97.6773 14.7269C97.2469 15.2551 97.326 16.032 97.8542 16.4617L109.091 25.6232C109.619 26.0537 110.396 25.9751 110.827 25.447L112.566 23.3126C112.996 22.7847 112.918 22.0075 112.389 21.5773ZM106.586 17.2245C106.071 16.8053 105.995 16.05 106.414 15.5362C106.833 15.0223 107.588 14.9446 108.102 15.3641C108.616 15.783 108.692 16.5386 108.273 17.0525C107.855 17.5661 107.1 17.6435 106.586 17.2245Z"
            fill="#BBBDBF"
          />
          <path
            d="M96.5645 22.804L96.027 23.4634L110.23 35.0424L110.768 34.383L96.5645 22.804Z"
            fill="#929597"
          />
          <path
            d="M95.1949 24.4835L94.6572 25.1431L108.861 36.722L109.398 36.0624L95.1949 24.4835Z"
            fill="#BBBDBF"
          />
          <path
            d="M93.8271 26.1626L93.2894 26.822L107.492 38.4015L108.03 37.742L93.8271 26.1626Z"
            fill="#BBBDBF"
          />
          <path
            d="M92.4552 27.8426L91.9175 28.5022L106.121 40.0812L106.659 39.4216L92.4552 27.8426Z"
            fill="#BBBDBF"
          />
          <path
            d="M81.749 25.2476L81.2117 25.9067L103.259 43.8811L103.796 43.222L81.749 25.2476Z"
            fill="#929597"
          />
          <path
            d="M80.5784 26.6834L80.0403 27.3435L102.088 45.3182L102.626 44.6582L80.5784 26.6834Z"
            fill="#BBBDBF"
          />
          <path
            d="M79.4086 28.121L78.871 28.7805L100.918 46.7556L101.456 46.0961L79.4086 28.121Z"
            fill="#BBBDBF"
          />
          <path
            d="M78.2357 29.5583L77.6976 30.2183L99.7453 48.1928L100.283 47.5329L78.2357 29.5583Z"
            fill="#BBBDBF"
          />
          <path
            d="M77.0645 30.9941L76.5262 31.6543L98.5738 49.6297L99.112 48.9695L77.0645 30.9941Z"
            fill="#BBBDBF"
          />
          <path
            d="M75.8944 32.4318L75.3569 33.0911L97.4045 51.0657L97.942 50.4064L75.8944 32.4318Z"
            fill="#BBBDBF"
          />
          <path
            d="M73.0332 35.9422L72.4958 36.6013L94.5436 54.5758L95.081 53.9166L73.0332 35.9422Z"
            fill="#929597"
          />
          <path
            d="M71.8599 37.3788L71.3224 38.0381L93.3694 56.0131L93.9069 55.3539L71.8599 37.3788Z"
            fill="#BBBDBF"
          />
          <path
            d="M70.6894 38.8152L70.1511 39.4753L92.1982 57.4499L92.7365 56.7897L70.6894 38.8152Z"
            fill="#BBBDBF"
          />
          <path
            d="M69.5189 40.2528L68.9818 40.9116L91.0289 58.8861L91.566 58.2273L69.5189 40.2528Z"
            fill="#BBBDBF"
          />
          <path
            d="M106.586 17.2245C106.071 16.8053 105.995 16.05 106.414 15.5362C106.833 15.0223 107.588 14.9446 108.102 15.3641L108.833 14.4685L107.431 13.3244C106.903 12.8939 106.126 12.9738 105.696 13.5017L104.413 15.0748L101.153 12.416C100.625 11.9861 99.8474 12.0652 99.4172 12.5925L97.6773 14.7269C97.2469 15.2551 97.326 16.032 97.8542 16.4617L103.473 21.043L106.586 17.2245Z"
            fill="#A6A8AB"
          />
          <path
            d="M90.5321 26.6208C90.3085 26.8951 89.9048 26.9363 89.6302 26.7126L84.6766 22.6739C84.4026 22.4504 84.3614 22.0465 84.5852 21.7716L88.6238 16.8184C88.8478 16.5441 89.2512 16.5036 89.5258 16.7269L94.479 20.7649C94.7536 20.9886 94.7941 21.3926 94.5705 21.6666L90.5321 26.6208Z"
            fill="#67A39D"
          />
          <path
            d="M28.3321 69.4117C46.809 93.7856 53.558 72.6814 63.7453 72.737C69.0805 72.7648 73.003 94.7028 99.1457 69.2736C99.1066 49.7191 83.2248 33.8959 63.67 33.9337C44.1148 33.9721 28.2934 49.8562 28.3321 69.4117Z"
            fill="#E8AF2E"
          />
          <path
            d="M99.1474 69.2736C95.3092 91.3697 84.6458 94.0944 78.87 117.195C66.2498 125.631 67.2172 123.687 46.8595 118.916C43.9093 97.6705 28.3643 84.8832 28.3339 69.4118C27.3192 46.291 96.1996 50.6882 99.1474 69.2736Z"
            fill="#E8AF2E"
          />
          <path
            d="M79.7558 76.0393C79.7558 76.0393 73.0757 91.46 71.0589 97.1794C69.0422 102.898 69.0115 116.545 69.0115 116.545"
            stroke="#403F41"
            stroke-width="1.71767"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M45.3035 76.1064C45.3035 76.1064 52.0436 91.5011 54.0823 97.2114C56.1212 102.923 56.2067 116.57 56.2067 116.57"
            stroke="#403F41"
            stroke-width="1.71767"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M75.0507 127.888L75.0722 138.815C75.078 142.174 72.9047 144.902 70.2145 144.907L55.6057 144.936C52.9199 144.941 50.7356 142.221 50.7295 138.863L50.7073 127.935L75.0507 127.888Z"
            fill="#A6A8AB"
          />
          <path
            d="M81.0897 114.475L81.1235 132.191C81.1302 135.55 78.4126 138.279 75.055 138.286L50.7447 138.333C47.3865 138.34 44.6576 135.621 44.6509 132.262L44.6171 114.546L81.0897 114.475Z"
            fill="#221F1F"
          />
          <path
            d="M69.6085 81.6882C69.6137 84.2479 71.2015 86.3209 73.1497 86.3163C75.1004 86.313 76.6765 84.2337 76.6713 81.6739C76.6667 79.117 75.0834 77.0432 73.133 77.0481C71.1828 77.0512 69.604 79.1298 69.6085 81.6882Z"
            stroke="#403F41"
            stroke-width="1.43292"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M62.5443 81.7017C62.5492 84.2619 64.1338 86.3348 66.0859 86.3299C68.0358 86.3272 69.6133 84.2479 69.6082 81.6882C69.6036 79.1299 68.0178 77.0573 66.0676 77.0615C64.1164 77.0658 62.5386 79.1445 62.5443 81.7017Z"
            stroke="#403F41"
            stroke-width="1.43292"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M55.4771 81.7155C55.4813 84.2748 57.0672 86.3486 59.0193 86.3443C60.9708 86.34 62.5484 84.262 62.5435 81.7017C62.5377 79.1445 60.9526 77.0713 59.0008 77.0759C57.0483 77.0793 55.4716 79.1583 55.4771 81.7155Z"
            stroke="#403F41"
            stroke-width="1.43292"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M48.4129 81.7292C48.418 84.2888 50.002 86.3617 51.9544 86.3583C53.9046 86.3544 55.4813 84.2748 55.477 81.7155C55.4715 79.1583 53.886 77.0851 51.9361 77.0894C49.9835 77.0924 48.4083 79.1717 48.4129 81.7292Z"
            stroke="#403F41"
            stroke-width="1.43292"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M74.8813 40.4168C66.132 33.9857 50.5541 39.4992 44.7884 43.7062C36.7739 49.5526 30.8748 62.4391 31.8165 70.763C32.7587 79.0853 36.2871 83.4694 39.5759 91.2106C40.7455 93.9627 35.579 82.8217 41.8046 67.778C46.4502 56.5503 59.9415 53.6618 68.8857 52.6102C75.1477 51.8736 83.5854 46.8139 74.8813 40.4168Z"
            fill="#FDE039"
          />
          <path
            d="M43.3932 119.888C43.3947 120.371 43.7682 120.761 44.2269 120.761L81.6793 120.687C82.1396 120.687 82.5113 120.295 82.5103 119.811L82.5067 118.291C82.5058 117.809 82.1325 117.417 81.6738 117.418L44.2196 117.492C43.7609 117.492 43.3889 117.885 43.3901 118.368L43.3932 119.888Z"
            fill="#403F41"
          />
          <path
            d="M43.4025 125.116C43.404 125.6 43.7769 125.99 44.2365 125.99L81.6892 125.916C82.1488 125.916 82.5202 125.523 82.5193 125.04L82.5166 123.52C82.5157 123.037 82.1431 122.645 81.684 122.646L44.2301 122.72C43.7702 122.72 43.3988 123.113 43.3994 123.597L43.4025 125.116Z"
            fill="#403F41"
          />
          <path
            d="M43.3268 130.345C43.3283 130.828 43.7015 131.219 44.1601 131.218L81.6135 131.145C82.0727 131.144 82.4444 130.752 82.4426 130.269L82.441 128.75C82.4398 128.266 82.0666 127.875 81.6068 127.876L44.154 127.948C43.6948 127.949 43.3234 128.343 43.3243 128.826L43.3268 130.345Z"
            fill="#403F41"
          />
          <path
            d="M122.535 41.5033L59.4444 15.1567L55.8561 23.7499L118.945 50.0957L122.535 41.5033ZM116.331 43.9585C116.574 43.3758 117.244 43.1004 117.827 43.3436C118.41 43.5875 118.685 44.2575 118.441 44.8402C118.198 45.4226 117.529 45.6981 116.945 45.4548C116.363 45.2112 116.087 44.5418 116.331 43.9585Z"
            fill="#FCCA3A"
          />
          <path
            d="M111.084 41.829C111.157 41.655 111.075 41.4558 110.901 41.3828C110.728 41.3109 110.528 41.3925 110.456 41.5669L108.684 45.8099L109.312 46.0732L111.084 41.829Z"
            fill="#57585A"
          />
          <path
            d="M107.755 42.4081C107.828 42.2349 107.745 42.0355 107.572 41.963C107.398 41.8903 107.199 41.9724 107.125 42.146L106.054 44.7133L106.683 44.9763L107.755 42.4081Z"
            fill="#57585A"
          />
          <path
            d="M105.133 41.3118C105.205 41.1372 105.123 40.9386 104.949 40.8659C104.775 40.7937 104.576 40.8751 104.503 41.0491L103.432 43.6162L104.061 43.8789L105.133 41.3118Z"
            fill="#57585A"
          />
          <path
            d="M102.501 40.2138C102.573 40.0405 102.491 39.8402 102.318 39.7686C102.144 39.6962 101.944 39.7777 101.872 39.951L100.8 42.5189L101.429 42.7813L102.501 40.2138Z"
            fill="#57585A"
          />
          <path
            d="M100.578 37.4403C100.65 37.2674 100.569 37.0674 100.395 36.9949C100.221 36.9225 100.021 37.004 99.9489 37.1776L98.1764 41.4217L98.8052 41.685L100.578 37.4403Z"
            fill="#57585A"
          />
          <path
            d="M97.2507 38.0199C97.3231 37.846 97.2409 37.6467 97.0679 37.5742C96.8935 37.5018 96.6941 37.5839 96.6222 37.7572L95.5498 40.3251L96.1784 40.5872L97.2507 38.0199Z"
            fill="#57585A"
          />
          <path
            d="M94.618 36.9235C94.6903 36.7499 94.608 36.5499 94.4353 36.4774C94.2613 36.4047 94.0624 36.4869 93.9894 36.6604L92.9171 39.228L93.5461 39.4898L94.618 36.9235Z"
            fill="#57585A"
          />
          <path
            d="M91.9929 35.8254C92.0661 35.6524 91.9844 35.4526 91.8103 35.3799C91.6367 35.3075 91.4369 35.3891 91.3649 35.5629L90.2926 38.1305L90.9216 38.3923L91.9929 35.8254Z"
            fill="#57585A"
          />
          <path
            d="M90.064 33.0514C90.1363 32.8787 90.0548 32.6782 89.8809 32.6059C89.7075 32.5336 89.508 32.6154 89.4356 32.7893L87.663 37.0336L88.2919 37.2963L90.064 33.0514Z"
            fill="#57585A"
          />
          <path
            d="M86.7412 33.6315C86.8133 33.4578 86.7315 33.2583 86.5579 33.1859C86.3842 33.1139 86.185 33.195 86.112 33.3685L85.0394 35.9365L85.669 36.1989L86.7412 33.6315Z"
            fill="#57585A"
          />
          <path
            d="M84.111 32.5341C84.184 32.3606 84.1018 32.1612 83.9279 32.0893C83.7545 32.016 83.555 32.0984 83.482 32.2717L82.4098 34.8393L83.0393 35.1017L84.111 32.5341Z"
            fill="#57585A"
          />
          <path
            d="M81.4862 31.4374C81.5587 31.2634 81.4764 31.0642 81.3034 30.9918C81.1291 30.9193 80.9297 31.0009 80.8572 31.175L79.7853 33.7418L80.4144 34.0042L81.4862 31.4374Z"
            fill="#57585A"
          />
          <path
            d="M79.559 28.663C79.6316 28.49 79.5493 28.2901 79.3759 28.2176C79.2023 28.1452 79.0028 28.2267 78.9304 28.4006L77.1576 32.6453L77.7864 32.9071L79.559 28.663Z"
            fill="#57585A"
          />
          <path
            d="M76.2306 29.2435C76.303 29.0694 76.2215 28.8699 76.0479 28.7975C75.8737 28.7247 75.6744 28.807 75.6018 28.9807L74.53 31.548L75.1584 31.811L76.2306 29.2435Z"
            fill="#57585A"
          />
          <path
            d="M73.5853 28.1373C73.6578 27.964 73.5755 27.7638 73.4021 27.6919C73.2288 27.6194 73.0293 27.7016 72.9563 27.8746L71.885 30.4427L72.5136 30.7045L73.5853 28.1373Z"
            fill="#57585A"
          />
          <path
            d="M70.9537 27.0399C71.0266 26.867 70.9446 26.667 70.7708 26.5944C70.5973 26.5221 70.3976 26.6042 70.3254 26.7772L69.2533 29.3448L69.8819 29.6069L70.9537 27.0399Z"
            fill="#57585A"
          />
          <path
            d="M63.0525 23.7404C63.1247 23.5668 63.0427 23.3678 62.8693 23.2947C62.6954 23.2223 62.4954 23.3041 62.4238 23.4777L61.351 26.0452L61.9801 26.3077L63.0525 23.7404Z"
            fill="#57585A"
          />
          <path
            d="M60.4273 22.6429C60.5003 22.4696 60.4182 22.27 60.2449 22.1972C60.0716 22.1248 59.8714 22.2072 59.7992 22.3808L58.7264 24.9481L59.3557 25.2105L60.4273 22.6429Z"
            fill="#57585A"
          />
          <path
            d="M69.029 24.2663C69.1021 24.0926 69.0199 23.8931 68.8455 23.821C68.6722 23.7482 68.4729 23.8302 68.4 24.0035L66.6277 28.2481L67.2572 28.5105L69.029 24.2663Z"
            fill="#57585A"
          />
          <path
            d="M65.702 24.8464C65.775 24.6725 65.6925 24.4732 65.519 24.4008C65.3449 24.3282 65.146 24.4096 65.0731 24.5833L64.0011 27.1509L64.63 27.413L65.702 24.8464Z"
            fill="#57585A"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_103_3989"
            x="0.489766"
            y="0"
            width="133.858"
            height="179.044"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="3.91892" dy="7.83784" />
            <feGaussianBlur stdDeviation="3.71458" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_103_3989"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_103_3989"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};
